.footer{
    display: grid;
    place-items: center;
    width: 100%;
    height: 10vh;
   
    color: #fff;
    box-shadow: none;
    border: none;
    margin:  0;
    padding: 0;
    
}
.footer__container{
     display:flex;
    justify-content: space-between;
    align-items: center;
    transition: var(--transition)  

   
  
}
.footer__logo a:nth-child(1){
    margin-left: 0;
}
.footer__logo a {
    font-family: var(--font-family-body);
    color: #fff;
    transition: var(--transition);
    margin-left: 0.8rem;
    
}
.copyright{
    font-family: var(--font-family-body);
    color: #fff;
}




.footer__logo a:hover{

   
    
    color: var(--primary-color);

    
}
.hei__add{
    display: flex;
    gap: 0.1rem;
    font-family: var(--font-family-body);
  
    cursor: pointer;
    /* background-color: #1d3c45; */
    padding: 0.4rem 3%;
    border-radius: 80rem;
    font-optical-sizing: auto;
    opacity: 0.65;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.hei__add h3{
    font-size: 1rem;
    white-space: nowrap;
}
.hei__add span a{
    font-family: var(--font-family-body);
    color: #fff;
}


/* .footer__logo:hover{
    color: #a85d68;
} */
@media screen and (max-width: 850px){
    .hei__add{
        flex-direction: column;
      
       
    }
    .hei__add h3,
     .hei__add span a{
        font-size: 0.7rem;
    }
}

@media screen and (max-width: 750px){
    
    .footer__container{
        
    

       }
    .copyright{
        font-size: 0.6rem;
        margin-top: 0.2rem;
    }
}

 @media screen  and (max-width: 680px) {
        .footer__container{
         flex-direction: column-reverse;
            

        }
}

