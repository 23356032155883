header{
    position: relative;
    
    height: 80vh;
    background-color: rgba(0,0,0,)
}


.header__title{
    position: absolute;
    width: 100%;
    height:100%;
    top:0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
     margin-right: 10px;
   
}
.button__div a{
    font-family: var(--font-family-body);
    color:#fff;
    font-size: 15px;
    font-weight: 700;
    text-align: center;

}

.button__div p {
    font-family: var(--font-family-body);
    color:#fff;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    margin: 0;
}




.header__btnOne{
    border: 1px solid var(--primary-color);
    border-radius: 6px;
    padding: 1rem 3rem;
    background-color: var(--primary-color);
    gap: 1rem;
    cursor:pointer;
}


.header__btnTwo{
    
    border: 1px solid var(--primary-color);
    border-radius: 6px;
    padding:1rem 4rem;
    background-color: var(--primary-color-hover);
    gap: 1rem;
    backdrop-filter: blur(25px);
    cursor: pointer;
   }

   .header__btnOne:hover{
    background-color: var(--primary-color-hover);
    backdrop-filter: blur(25px);
    transition: var(--transition)
  }

  .header__btnTwo:hover{
    color: #ffffff;
    background-color: var(--primary-color);
    transition: var(--transition);
    border: 1px solid rgba(200, 55,55, 0.9);
  }
  
h1{
    font-family: var(--font-family-heading);
   
    color: #fff;
    font-size: 5rem;
    line-height: 80px;
    font-weight: 600;
    text-align: center;
  
  }
  p{
    font-family: var(--font-family-body);
  }

.button__div{
    display: flex;
    padding: 1rem 4rem;
    gap: 1rem;
   
}
.bg-video{
    width: 100%;
    height: 100%;

}

p{
    margin: 1rem 0;
    color:#fff
}

@media screen and (max-width: 991px){
    h1{
        font-size: 4rem;
        line-height: 4rem;
    }
    p{
        margin: 1rem 0;
    }
    .button__div p{
        font-family: var(--font-family-body);
        color:#fff;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
       width: 100%;
       padding: 0;
       margin: 0;
    }
 
    .header__btnOne p{

        white-space: nowrap ;
    }


}
@media screen and (max-width: 767px){
h1{
    font-size: 3rem;
    line-height: 4rem;
    padding: 0 2rem;
    }
    .nav__logo{
        width: 100px;
    }
    .button__div{
        flex-direction: column;
        padding: 1rem 4rem;
        gap: 1rem;
       
    }
    .header__btnOne,
   .header__btnTwo{
        padding:1rem 6rem;
    }
}

@media screen and (max-width: 450px){
    h1{
        font-size: 2.4rem;
        }
}