nav{
    width: 100%;
    height: 10vh;
    display: flex; 
    transition: var(--transition);
}

.nav__logo img{
    width: 150px;
}
.nav__container{
    display: flex;
    justify-content: space-between;
    align-items: center;


}
.scale-up-ver-top {
    z-index: 10;
    -webkit-animation: scale-up-ver-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            animation: scale-up-ver-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}





@-webkit-keyframes scale-up-ver-top {
0% {
  -webkit-transform: scaleY(0.4);
          transform: scaleY(0.4);
  -webkit-transform-origin: 100% 0%;
          transform-origin: 100% 0%;
}
100% {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
  -webkit-transform-origin: 100% 0%;
          transform-origin: 100% 0%;
}
}
@keyframes scale-up-ver-top {
0% {
  -webkit-transform: scaleY(0.4);
          transform: scaleY(0.4);
  -webkit-transform-origin: 100% 0%;
          transform-origin: 100% 0%;
}
100% {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
  -webkit-transform-origin: 100% 0%;
          transform-origin: 100% 0%;
}
}



/* dropdown menu */
.nav__menu-container-link{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: var(--color-footer);
    padding:2rem;
    position: absolute;
    top:40px;
    right:0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius:5px;

}

.nav__menu {
    display: flex;
    gap: 2rem;
    font-weight: 400;
    background-color: #1d3c45;
    padding: 0.6rem 5rem;
    border-radius: 80rem;
    font-optical-sizing: auto;
    opacity: 0.65;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}


.nav__menu a{
    font-size: 16px;
    font-family: var(--font-family-body);
    color: #f7fefc;
    font-weight: 400;margin: 0;
    
}
.nav__menu a:hover{
    color: #b2b7b6;
    transition: var(--transition);
}




.btnn{
    font-weight: 700;
    text-align: center;
    cursor: pointer;
    display:inline-block; 
    width:fit-content; 
    border: 1px solid var(--primary-color);
    border-radius: 6px;
    padding: 0.6rem 3rem;
    background-color: var(--primary-color);
    transition: .2s;
  
   }

   .btnn a{
    font-family: var(--font-family-body);
    color:#fff;
    font-size: 15px;
   }

   .btnn p{
    font-family: var(--font-family-body);
    color:#fff;
    font-size: 15px;
    margin: 0;
   }
  

  .btnn:hover{
        transition: var(--transition);
        color: #ffffff;
        border: 1px solid rgba(142, 94, 36, 0.9);
        background-color: var(--primary-color-hover);
        backdrop-filter: blur(25px);
  }

  .navbar-menu{
    display: none;
  }

  @media screen  and (max-width: 1024px){
    .btnn{
        display: none;
    }
    .nav__menu{
        display: none;
    }
    .navbar-menu{
        display: flex;
        cursor: pointer;
    }
    .navbar-menu{
        transition: var(--transition);
    }
    .hamburgar{
        transition:var(--transition);
        transform: rotate(180deg);
      }
     
    .nav__menu-container-link{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        background: rgba(22,36,43,0.2);
        backdrop-filter: blur(25px);
        padding:2rem;
        position: absolute;
        top:60px;
        right:0;
        margin-top: 1rem;
        min-width: 100%;
        border-radius:5px;
        line-height: 2rem;
        z-index: 10;
            
    }
    .nav__menu-link li {
        margin: 1rem 0;
    }
    .nav__menu-btnn {
        border: 1px solid var(--primary-color);
        border-radius: 6px;
        padding:0.6rem 2.5rem;
        background-color:var(--primary-color);
        transition: .2s;
        font-family: var(--font-family-body);
        color:#fff;
        font-size: 15px;
        font-weight: 700;
        text-align: center;
        gap: 1rem;
        backdrop-filter: blur(25px);
        cursor: pointer;margin-top: 0.5rem;
    }
    .nav__menu-btnn:hover{
        transition: var(--transition);
        color: #ffffff;
        border: 1px solid rgba(142, 94, 36, 0.9);
        background-color: var(--primary-color-hover);
        backdrop-filter: blur(25px);
    }
    
    
    .nav__menu-container-link a {
        font-size: 1.2rem;
        padding: 0.5rem;
        font-family: var(--font-family-body);
        color: #fff;
        font-weight: 700;
    }
    .nav__menu-container-link a:hover{
        color: #adb5b3;
    }
    .nav__logo img {
        width: 100px;
    }
  }
  @media screen  and (max-width: 600px){
    h1{font-size:0.2rem}

   

    .nav__menu-container-link{
        display: flex;
       justify-content: center;
       align-items: center;
       flex-direction: column;
       text-align: center;
       background: rgba(0,0,0,0.1);
       /* backdrop-filter: blur(25px); */
       padding:2rem;
       position: absolute;
       top:40px;
       right:0;
       margin-top: 1rem;
       width: 100%;
       border-radius:5px;
       line-height: 2rem;
       z-index: 10;
       box-shadow: 0 0 5 rgba(0,0,0,0.2);       
   }
    
  }